<!-- src/components/organisms/StepperV.vue -->
<template>
  <div class="how-it-works">
    <div class="line"></div>
    <div class="step" v-for="(step, index) in steps" :key="index">
      <div class="icon-container">
        <vue-feather
          :type="step.icon"
          size="24"
          class="step-icon"
        ></vue-feather>
      </div>
      <div class="step-content">
        <h3 class="step-title">{{ step.title }}</h3>
        <p class="step-description">{{ step.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VerticalStepperComponent",
  props: {
    steps: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.how-it-works {
  display: flex;
  flex-direction: column; /* Stack steps vertically */
  align-items: flex-start; /* Align items to the start */
  margin: 3rem 0;
  position: relative;
}

.line {
  position: absolute;
  top: 0; /* Start from the top */
  left: 1.5rem; /* Adjust to align with icons */
  height: 100%; /* Full height */
  width: 2px; /* Line thickness */
  background-color: #f2f5f9; /* Line color */
  z-index: -1; /* Ensure the line is behind the icons */
}

.step {
  display: flex; /* Use flexbox for horizontal alignment */
  align-items: center; /* Center items vertically */
  padding: 1rem 0; /* Vertical padding for each step */
  width: 100%; /* Full width for each step */
}

.icon-container {
  background-color: #f2f5f9; /* Light background for icons */
  border-radius: 50%;
  padding: 1rem;
  margin-right: 1rem; /* Space between icon and text */
}

.step-content {
  display: flex;
  flex-direction: column; /* Stack title and description vertically */
}

.step-icon {
  color: #333; /* Icon color */
}

.step-title {
  font-weight: bold;
  margin: 0; /* Remove default margin */
  color: #333; /* Title color */
}

.step-description {
  color: #777; /* Description color */
  margin-top: 0.5rem; /* Space between title and description */
}
</style>
