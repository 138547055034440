<template>
  <form @submit.prevent="join">
    <img src="@/assets/logo_white.png" alt="Logo" class="logo" />
    <h2>Ready to Join?</h2>
    <p class="subheading">Setup your audio before joining</p>

    <!-- Add a section here for showing active user in call count -->
    <input
      v-model="name"
      type="text"
      name="name"
      placeholder="Enter your name"
    />

    <button type="submit" class="join-button">
      {{ joinInProgress ? "Joining..." : "Join Now" }}
    </button>
  </form>
</template>

<script setup>
import { hmsActions } from "@/utils/hms";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useHmsStore } from "@/stores/hms";
import { useRoute } from "vue-router";
import { useToast, POSITION } from "vue-toastification";
import { amplitude } from "../plugins/amplitude";
import { query, collection, getDocs, where } from "firebase/firestore";
import { db } from "../plugins/firebase";

const router = useRouter();
const toast = useToast();
const hmsStore = useHmsStore();
const route = useRoute();
const roomCode = ref("");
let joinInProgress = ref(false);

const userKey = "name";
const urlParams = new URLSearchParams(window.location.search);

onMounted(() => {
  roomCode.value = route.params.roomCode || "";
});

async function getFlightDetails(roomCode) {
  try {
    console.log("Fetching flight details for room code:", roomCode);
    const q = query(
      collection(db, "flights"),
      where("roomCode", "==", roomCode)
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const flightDetails = querySnapshot.docs[0].data();
      return { id: querySnapshot.docs[0].id, ...flightDetails };
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching flight details: ", error);
    return null;
  }
}

const flightDetails = ref(null); // State to store flight details

onMounted(async () => {
  flightDetails.value = await getFlightDetails(roomCode.value);
});

// support for query params to directly populate the name and auth token values
let name = urlParams.get(userKey) || localStorage.getItem(userKey) || "";
let token = "";

const joinAsMuted = false;
const settings = joinAsMuted ? { isAudioMuted: true, isVideoMuted: true } : {};

async function join() {
  token = flightDetails.value.token;

  if (!token) {
    toast.error("This meeting is no longer active.", {
      position: POSITION.BOTTOM_LEFT,
    });
    return; // Exit if no valid token is available
  }
  // set the values in localstorage to avoid taking in every time
  localStorage.setItem(userKey, name);

  try {
    joinInProgress.value = true;
    hmsStore.token = token;
    toast.clear(); // it's a new start
    await hmsActions.join({
      userName: name,
      authToken: token,
      rememberDeviceSelection: true,
      settings,
    });
    router.push({
      name: "FlightPortal",
      params: {
        roomCode: roomCode.value,
      },
    });

    amplitude.track("Join Flight", {
      type: "join_flight",
      name: name,
    });
  } catch (err) {
    toast.error(`Can't join => ${err.message}: ${err.description || ""}`, {
      position: POSITION.BOTTOM_LEFT,
    });
  } finally {
    joinInProgress.value = false;
  }
}
</script>

<style lang="scss" scoped>
form {
  width: min(100%, 500px);
  height: auto; // Ensure the form can grow in height based on content

  position: absolute; // Positioning it absolutely to center it
  top: 50%; // Center vertically
  left: 50%; // Center horizontally
  transform: translate(-50%, -50%); // Adjust the exact centering

  margin: auto; // Centering horizontally for older browsers
  padding: 20px;

  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  border-radius: 8px;

  display: flex; // Using flex to align children
  flex-direction: column; // Stack children vertically
  align-items: center; // Center-align the child elements
  justify-content: center; // Center content vertically in the container

  h2 {
    font-size: 28px;
    margin-bottom: 10px;
    font-weight: 500;
    color: antiquewhite;
  }
}

.subheading {
  font-size: 12px;
  color: rgb(163, 155, 155);
  font-weight: 500;
  text-align: left;
  margin-bottom: 30px;
}

.logo {
  display: block;
  margin: 0 auto 20px; // Center the logo and add some margin below
  max-width: 30px; // Adjust the size as needed
  height: auto; // Maintain aspect ratio
  margin-bottom: 40px;
}

input {
  display: block;

  width: 100%;
  height: 45px;
  font-size: 14px;
  padding: 20px;

  color: inherit;
  font-family: inherit;

  background: #13141a;

  border-radius: 12px;
  border: 2px solid transparent;

  margin-bottom: 20px;
  &::placeholder {
    color: #aaa;
  }
}

input[type="text"]:focus {
  outline: none; /* Removes the default focus outline */
  border-color: #1565c0; /* Sets the border color to blue */
}

.join-button {
  padding: 10px 20px; // Increase padding to make the button taller
  font-size: 14px; // Optionally increase the font size if needed
  background-color: #1565c0; // Example background color
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  height: 45px;
  &:hover {
    background-color: #104e93; // Darker shade on hover
  }
  &:disabled {
    background-color: #104e93; // Gray background for disabled state
    color: #aaa; // Dark gray text for disabled state
    cursor: not-allowed; // Cursor to indicate the button is not clickable
  }
}
</style>
