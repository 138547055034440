import { createRouter, createWebHistory } from "vue-router";
import LoginWithToken from "../views/LoginWithToken.vue";
import FlightPortal from "../views/FlightPortal.vue";
import FlightPortalMobile from "../views/FlightPortalMobile.vue";
// import SignUp from "../views/SignUp.vue";
// import Login from "../views/Login.vue";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../plugins/firebase.js";
import Home from "../views/Home.vue";
import Dashboard from "../views/Dashboard.vue";
import How from "../views/How.vue";
import { isMobile } from "@/utils/helper";
import { useSessionStore } from "../stores/session.js";
import NewListing from "../views/NewListing.vue";
import Listings from "../views/Listings.vue";
import Listing from "../views/Listing.vue";
import Billing from "../views/Billing.vue";
import Confirmation from "../views/Confirmation.vue";
import Bookings from "../views/Bookings.vue";
import Cancelled from "../views/Cancelled.vue";
import HomeMobile from "../views/HomeMobile.vue";
import ListingMobile from "../views/ListingMobile.vue";
import Terms from "../views/Terms.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import Education from "../views/Education.vue";
import EducationMobile from "../views/EducationMobile.vue";
import BecomeAPilot from "../views/BecomeAPilot.vue";
import BecomeAPilotMobile from "../views/BecomeAPilotMobile.vue";
// import Landing from "../views/Landing.vue";

const isMobileDevice = isMobile();

const routes = [
  // {
  //   path: "/",
  //   name: "Landing",
  //   component: Landing,
  // },
  // {
  //   path: "/",
  //   name: "Landing",
  //   component: How,
  // },
  // {
  //   path: "/login",
  //   name: "Login",
  //   component: Login,
  // },
  // {
  //   path: "/signup",
  //   name: "SignUp",
  //   component: SignUp,
  // },
  {
    path: "/",
    name: "Home",
    component: isMobileDevice ? HomeMobile : Home,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/how-it-works",
    name: "How",
    component: How,
  },
  {
    path: "/education",
    name: "Education",
    component: isMobileDevice ? EducationMobile : Education,
  },
  {
    path: "/become-a-pilot",
    name: "Become a Pilot",
    component: isMobileDevice ? BecomeAPilotMobile : BecomeAPilot,
  },
  {
    path: "/new-flight",
    name: "NewFlight",
    component: LoginWithToken,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/list-an-event",
    name: "NewListing",
    component: NewListing,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/listings",
    name: "Listings",
    component: Listings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/bookings",
    name: "Bookings",
    component: Bookings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/listing",
    name: "Listing",
    component: isMobileDevice ? ListingMobile : Listing,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/confirmation",
    name: "Confirmation",
    component: Confirmation,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cancelled",
    name: "Cancelled",
    component: Cancelled,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:roomCode",
    name: "FlightPortal",
    component: isMobileDevice ? FlightPortalMobile : FlightPortal,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: PrivacyPolicy,
  },
];

const router = createRouter({
  history: createWebHistory(),
  mode: "hash",
  routes,
});

router.beforeEach((to, from, next) => {
  const sessionStore = useSessionStore();

  // Check the authentication state regardless of whether the route requires auth
  onAuthStateChanged(auth, (user) => {
    if (user) {
      console.log("User is authenticated:", user.uid);
      sessionStore.setUserId(user.uid);
    } else {
      console.log("User is not authenticated");
      sessionStore.setUserId(null);
    }

    // Check if the route requires authentication
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (user) {
        next(); // Proceed if the user is authenticated
      } else {
        next({ path: "/" }); // Redirect to home if not authenticated
      }
    } else {
      next(); // Allow navigation for public routes
    }
  });
});

// router.beforeEach((to, from, next) => {
//   const sessionStore = useSessionStore();
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     // If the route requires authentication, we use onAuthStateChanged to check auth state
//     onAuthStateChanged(auth, (user) => {
//       if (user) {
//         console.log("User is authenticated:");
//         sessionStore.setUserId(user.uid);
//         next(); // User is authenticated, proceed to the route
//       } else {
//         console.log("User is not authenticated");
//         sessionStore.setUserId(null);
//         next({ path: "/" }); // Not authenticated, redirect to login
//       }
//     });
//   } else {
//     next(); // Route does not require authentication
//   }
// });

export default router;
