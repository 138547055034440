<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <section class="vision-section">
    <h1 class="main-title">What Teachers Say</h1>
    <p class="main-subheading">What schools are saying about our field trips</p>
    <div class="vision-cards">
      <div class="vision-card" v-for="review in reviews" :key="review.name">
        <img :src="review.avatar" class="avatar" />
        <h2>{{ review.name }}</h2>
        <p>{{ review.comment }}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ReviewsSection",
  props: {
    reviews: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.main-title {
  font-size: 3rem;
  color: #000;
  margin-bottom: 1rem;
}

.main-subheading {
  font-size: 1rem;
  margin: 0 2rem;
  color: #464646;
  text-align: center;
  margin-bottom: 6rem;
}

.vision-section {
  margin: 2rem 0;
}

.vision-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  gap: 5rem;
}

.vision-card {
  border-radius: 8px;
  width: 300px;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.vision-card h2 {
  font-size: 2rem;
  color: #222;
  text-align: left;
  margin: 1rem 0;
  font-weight: 500;
}

.vision-card h3 {
  font-size: 1.5rem;
  color: #222;
  text-align: left;
  margin: 0.5rem 0;
  /* font-weight: 500; */
}

.vision-card p {
  font-size: 1rem;
  color: #7e7e7e;
  text-align: center;
  line-height: 1.3rem;
}

.vision-card span {
  color: #000;
}

.vision-card span:hover {
  text-decoration: underline;
}

.avatar {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
}
</style>
