<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="contact-container">
    <div class="form-section">
      <h2 class="main-title">Partner With Us</h2>
      <p class="main-subheading">
        Get in touch with us to learn more about our partnerships
      </p>
      <br />
      <form @submit.prevent="submitForm" class="contact-form">
        <div class="form-group">
          <label for="name">Name*</label>
          <input
            type="text"
            id="name"
            v-model="formData.name"
            placeholder="John Doe"
            required
          />
        </div>
        <div class="form-group">
          <label for="email">Email*</label>
          <input
            type="email"
            id="email"
            v-model="formData.email"
            placeholder="email@example.com"
            required
          />
        </div>
        <div class="form-group">
          <label for="school">Organization (if applicable)</label>
          <input
            type="text"
            id="school"
            v-model="formData.school"
            placeholder="Your Organization Name"
          />
        </div>
        <div class="form-group">
          <label for="message">Message (optional)</label>
          <textarea
            rows="2"
            maxlength="50"
            style="max-block-size: 60px; max-width: 100%"
            id="message"
            v-model="formData.message"
            placeholder="Your message here..."
          ></textarea>
        </div>
        <br />
        <button type="submit" class="contact-submit">Submit</button>
      </form>
    </div>
    <div v-show="showBanner" class="info-section">
      <img src="@/assets/lifestyle.webp" alt="Skyhost Logo" class="banner" />
      <div class="overlay">
        <h1 class="overlay-title">Your Drone, Your Stage</h1>
        <br />
        <p>
          Combine your love for drones and exploration by hosting live, scenic
          drone flights. Work remotely, connect globally, and earn effortlessly.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HireForm",
  props: {
    formData: {
      type: Object,
      required: true,
    },
    submitForm: {
      type: Function,
      required: true,
    },
    showBanner: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
.contact-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 7rem 0rem;
  gap: 5rem;
}

.form-section {
  padding: 3rem 0rem; /* Adjust padding for spacing */
  flex: 1; /* Takes up half of the container */
  max-width: fit-content;
}

.info-section {
  flex: 1; /* Takes up half of the container */
  width: 100%; /* Full width */
  max-height: 650px;
}

.overlay-title {
  font-size: 4rem;
}

.overlay {
  position: relative;
  width: 100%;
  top: -50%;
  left: 0%;
  width: 600px;
  padding: 3rem;
  text-align: left;
  /* opacity: 0.1; */
  /* background-color: #000; */
}

.overlay p {
  line-height: 1.4rem;
  width: 80%;
}

.banner {
  width: 700px;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  z-index: -1;
}

.main-title {
  font-size: 2.5rem; /* Adjusted font size */
  color: #000;
}

.main-subheading {
  font-size: 1rem;
  margin: 0.5rem 0rem;
  color: #464646;
}

.contact-form {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1.2rem;
  text-align: left;
}

.form-group label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  color: #555;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #000; /* Focus color */
}

.contact-form div {
  margin-bottom: 1rem;
}

.contact-form label {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.contact-form input,
.contact-form textarea {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.contact-form button {
  background-color: #000;
  color: white;
  padding: 12px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
}
</style>
