<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="app-container">
    <Header />

    <div class="title-container">
      <!-- <img src="@/assets/classroom.png" alt="Skyhost Logo" class="banner" /> -->
      <video
        src="@/assets/classroom1.mp4"
        autoplay
        muted
        loop
        class="banner"
      ></video>
      <!-- src/views/Education.vue -->
      <div class="overlay-container">
        <Vue-feather type="cast" size="32"></Vue-feather>
        <h1 class="overlay-title">Live Virtual Field Trips</h1>
        <!-- add subheading with input "that educates and inspires" -->
        <h3 class="overlay-subheading">that engage and inspire</h3>
        <div class="overlay-time-container">
          <vue-feather type="clock" size="16"></vue-feather>
          <p class="overlay-time">{{ currentTime }}</p>
        </div>
        <div class="overlay-description-container">
          <hr class="line-divider-light" />
          <br />
          <p class="overlay-description">
            Explore the world like never before with live drone tours that bring
            real-time adventures to your classroom.
          </p>
        </div>
        <!-- <input type="email" placeholder="me@email.com" class="overlay-input" /> -->
        <button class="overlay-button" @click="scrollToFlyWithUs">
          Fly With Us
        </button>
      </div>
    </div>

    <!-- Blog Content -->
    <div :class="mainContentClass">
      <div class="title-container">
        <h1 class="main-title">Bringing the World to Your Classroom</h1>
      </div>
      <p class="main-subheading">
        Bring education to life with live drone tours, where students and
        teachers can explore remote wonders in real time, right from their
        classroom.
      </p>
      <br />

      <div class="vision-cards">
        <div class="vision-card">
          <!-- <img src="@/assets/drones-can.jpg" class="card-image" /> -->
          <vue-feather
            type="radio"
            size="18"
            style="color: black"
          ></vue-feather>
          <h3>See real-time views</h3>
          <p>of incredible locations, from towering peaks to hidden forests.</p>
        </div>

        <div class="vision-card">
          <!-- <img src="@/assets/group-bond.jpg" class="card-image" /> -->
          <vue-feather
            type="help-circle"
            size="18"
            style="color: black"
          ></vue-feather>
          <h3>Ask questions live</h3>
          <p>to an expert drone pilot during the session.</p>
        </div>

        <div class="vision-card">
          <!-- <img src="@/assets/unsustainable.png" class="card-image" /> -->
          <vue-feather type="send" size="18" style="color: black"></vue-feather>
          <h3>Control the journey</h3>
          <p>by suggesting where the drone should fly next.</p>
        </div>
      </div>

      <div>
        <img src="@/assets/livestream_flight.png" class="flightportal" />
      </div>

      <div class="benefits-container">
        <div class="title-container">
          <h1 class="main-title">Why Live Drone Tours Work for Education</h1>
        </div>
        <br />
        <br />
        <br />
        <div class="benefit-cards">
          <div class="vision-card">
            <!-- <img src="@/assets/drones-can.jpg" class="card-image" /> -->
            <div class="benefit-icon">🎨</div>
            <h3>Immersive</h3>
            <p>Real-time views from breathtaking locations.</p>
          </div>
          <div class="vision-card">
            <!-- <img src="@/assets/drones-can.jpg" class="card-image" /> -->
            <div class="benefit-icon">📚</div>
            <h3>Educational</h3>
            <p>Ties directly to curriculum topics.</p>
          </div>
          <div class="vision-card">
            <!-- <img src="@/assets/drones-can.jpg" class="card-image" /> -->
            <div class="benefit-icon">🌍</div>
            <h3>Global</h3>
            <p>Explore stunning landscapes and hidden gems.</p>
          </div>
        </div>
      </div>

      <div class="sustainable-goals-section">
        <div class="title-container">
          <h1 class="main-title">Understand our common goals</h1>
        </div>
        <h3 class="main-subheading">
          Students can experience immersive journeys to locations and topics
          under UN’s Sustainable Development Goals.
        </h3>
        <div @click="navigateToSDG" style="cursor: pointer">
          <img src="@/assets/SDG.png" class="small-banner" />
        </div>
      </div>

      <div class="title-container">
        <h1 class="main-title">Choose from our stunning locations</h1>
      </div>
      <p class="main-subheading">
        Bring education to life with live drone tours, where students and
        teachers can explore remote wonders in real time, right from their
        classroom.
      </p>

      <div>
        <div class="grid-container">
          <div
            class="grid-item"
            :class="{ disabled: !flight.isActive }"
            v-for="flight in flights"
            :key="flight.id"
            @click="navigateToListing(flight.id)"
          >
            <div class="flight-content">
              <div class="image-container">
                <img
                  :src="
                    flight.bannerUrl ||
                    require(`@/assets/placeholder-image-listing.png`)
                  "
                  alt="Flight Image"
                  class="flight-image"
                />
                <!-- <div
                  v-if="!flight.isActive && !flight.isCompleted"
                  class="chip"
                >
                  Coming soon
                </div>
                <div v-else-if="flight.isCompleted" class="chip">Sold out</div> -->
              </div>
              <div class="flight-details">
                <div class="flight-header">
                  <h4>{{ getTruncatedDescription(flight.location, 35) }}</h4>
                  <h2>{{ flight.title }}</h2>
                  <br />
                  <hr class="line-divider" />
                </div>

                <div class="flight-info">
                  <p>{{ getTruncatedDescription(flight.description, 200) }}</p>
                  <hr class="line-divider" />
                </div>

                <div class="flight-footer">
                  <div class="price-container">
                    <span v-show="!flight.isActive"
                      >30 min · Private Live Tour</span
                    >
                    <!-- <span>Ends on</span> -->
                    <!-- <p>{{ flight.endDate }}</p> -->
                    <p v-show="flight.isActive">${{ flight.pricePerGuest }}</p>
                    <span v-show="flight.isActive">/ guest</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="title-container">
        <h1 class="main-title">Why You Need Live Drone Tours</h1>
      </div>
      <p class="main-subheading">
        Live drone tours offer a unique and engaging way to explore the world
        around you.
      </p>
      <br />

      <div class="how-it-works-container">
        <div class="vision-cards">
          <div class="vision-card">
            <!-- <img src="@/assets/academic-development.webp" class="card-image" /> -->
            <br />
            <h3>Field Trips That Truly Make Learning Stick</h3>
            <p>
              Studies show that regular field trips improve academic performance
              and foster greater tolerance for different opinions. Live drone
              tours create memorable learning experiences by connecting
              classroom lessons with real-world visuals.
            </p>
          </div>
          <div class="vision-card">
            <!-- <img src="@/assets/budgets.webp" class="card-image" /> -->
            <br />
            <h3>Budgets and logistics limit field trip opportunities</h3>
            <p>
              Traditional field trips are often expensive and difficult to
              organize, with issues like high travel costs, limited staff, and
              complex planning. Live drone tours remove these barriers, offering
              an easy, affordable, and enriching alternative.
            </p>
          </div>
          <div class="vision-card">
            <!-- <img
              src="@/assets/fieldtrip-participation.webp"
              class="card-image"
            /> -->
            <br />
            <h3>Bridge the Gap in Educational Access</h3>
            <p>
              Research shows that students' location, school type, and
              socio-economic status affect field trip attendance. Unfortunately,
              this limits access for students in underfunded or rural districts.
            </p>
          </div>
        </div>
      </div>

      <StepperComponent :steps="stepsData" />

      <ReviewsSection :reviews="reviewsData" />

      <ContactForm
        id="contact-form"
        :formData="formData"
        :submitForm="submitForm"
        showBanner
      />

      <Footer />
    </div>
  </div>
</template>

<script>
import { auth } from "@/plugins/firebase";
import { signOut } from "firebase/auth";
import { useSessionStore } from "../stores/session.js";
import { isMobile } from "@/utils/helper";
import { computed } from "vue";
import { amplitude } from "../plugins/amplitude.js";
import Header from "@/components/organisms/Header.vue";
import { truncateText } from "@/utils/helper";
import Footer from "@/components/Footer.vue";
import {
  addDoc,
  collection,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import ReviewsSection from "@/components/organisms/Reviews.vue";
import ContactForm from "@/components/organisms/ContactForm.vue";
import StepperComponent from "@/components/organisms/Stepper.vue";

export default {
  components: {
    Header,
    Footer,
    ReviewsSection,
    StepperComponent,
    ContactForm,
  },
  data() {
    return {
      stepsData: [
        {
          icon: "map", // Icon type for "Choose a Destination"
          title: "Choose a Destination",
          description: "Explore wildlife, volcanoes, and historical landmarks.",
        },
        {
          icon: "monitor", // Icon type for "Set Up in the Classroom"
          title: "Set Up in the Classroom",
          description: "Use a projector, internet, and curious minds.",
        },
        {
          icon: "mic", // Icon type for "Interact in Real-Time"
          title: "Interact in Real-Time",
          description: "Watch live drone feed and ask questions.",
        },
      ],
      reviewsData: [
        {
          name: "James H.",
          comment:
            "This was the easiest and most engaging field trip we've ever done. No travel, no stress—just pure excitement and immersive learning.",
          avatar: require("@/assets/mount-fuji.jpg"),
        },
        {
          name: "Sophia",
          comment:
            "Watching whales in their natural habitat was mesmerizing for the kids, and they learned so much about marine ecosystems directly from the guide.",
          avatar: require("@/assets/whale.jpg"),
        },
        {
          name: "Emily",
          comment:
            "Our 7th-graders were thrilled to interact with the drone pilot and ask questions in real time. It was an unforgettable way to bring our lesson to life!",
          avatar: require("@/assets/mt-fuji-hoei.jpg"),
        },
      ],
      flights: [],
      formData: {
        name: "",
        email: "",
        school: "",
        message: "",
        type: "education",
        createdAt: new Date(),
      },
    };
  },
  computed: {
    isUserAuthenticated() {
      const sessionStore = useSessionStore();
      return sessionStore.userId !== null;
    },
    currentTime() {
      const now = new Date();
      const options = { hour: "numeric", minute: "numeric", hour12: true };
      const localTime = new Intl.DateTimeFormat("en-US", options).format(now);

      // Get the GMT offset
      const gmtOffset = -now.getTimezoneOffset() / 60; // Convert to hours
      const gmtSign = gmtOffset >= 0 ? "+" : "-";
      const gmtHours = Math.abs(gmtOffset).toString().padStart(2, "0");

      return `${localTime} GMT${gmtSign}${gmtHours}:00`;
    },
  },
  methods: {
    navigateToSDG() {
      window.open("https://www.un.org/sustainabledevelopment/", "_blank");
    },
    scrollToFlyWithUs() {
      const contactFormSection = document.getElementById("contact-form");
      if (contactFormSection) {
        contactFormSection.scrollIntoView({ behavior: "smooth" });
      }
    },
    getTruncatedDescription(description, maxLength) {
      return truncateText(description, maxLength); // Adjust the character limit as needed
    },
    navigateToListing(listingId) {
      // this.$router.push(`/listing/${listingId}`);
      this.$router.push({
        path: `/listing`,
        query: { listingId: listingId },
      });
    },
    fetchFlights() {
      const db = getFirestore();
      const flightsQuery = query(
        collection(db, "listings"),
        orderBy("createdAt", "desc")
      );

      this.unsubscribe = onSnapshot(flightsQuery, (snapshot) => {
        const flights = snapshot.docs
          // .filter((doc) => doc.data().creatorUserId === auth.currentUser.uid)
          .map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          });

        this.flights = flights.map((flight) => {
          // const pricePerGuest =
          //   flight?.bookingOptions && flight.bookingOptions.length > 0
          //     ? flight.bookingOptions[0].price
          //     : 0;
          return {
            ...flight,
            pricePerGuest: 49,
            eventDate: flight.createdAt.toDate().toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            }),
            eventTime: flight.createdAt.toDate().toLocaleTimeString(),
            endDate: new Date(flight.endDate).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
            }),
          };
        });
      });
    },
    submitForm() {
      const db = getFirestore(); // Get Firestore instance
      const formCollection = collection(db, "inquiries"); // Replace with your collection name

      addDoc(formCollection, this.formData)
        .then(() => {
          console.log("Form data submitted successfully!");
          // Optionally, you can reset the form after submission
          this.formData = {
            name: "",
            email: "",
            school: "",
            message: "",
          };
        })
        .catch((error) => {
          console.error("Error submitting form data: ", error);
        });
    },
    login() {
      amplitude.track("Login from Blog", {
        type: "Blog to Login",
      });
      this.$router.push("/login");
    },
    logout() {
      signOut(auth)
        .then(() => {
          const sessionStore = useSessionStore();
          sessionStore.clearUser();
          this.$router.push("/");
        })
        .catch(() => {
          this.$router.push("/");
        });
    },
  },
  mounted() {
    const sessionStore = useSessionStore();
    this.fetchFlights();
    auth.onAuthStateChanged((user) => {
      if (user) {
        sessionStore.userId = user.uid;
      } else {
        sessionStore.userId = null;
      }
    });
  },
  setup() {
    const mainContentClass = computed(() => {
      return isMobile() ? "mobile-main-content" : "web-main-content";
    });
    const headerClass = computed(() => {
      return isMobile() ? "mobile-header" : "web-header";
    });

    return {
      mainContentClass,
      headerClass,
    };
  },
};
</script>

<style scoped>
/* General Styles */
.step-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 1rem;
}

.step-list-item {
  margin-bottom: 1rem;
  color: #7e7e7e;
}

.step-list-item strong {
  color: #000;
  font-size: 1.5rem;
  font-weight: 400;
}

.nested-list {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-top: 0.5rem;
}

.step-item-label {
  font-weight: bold;
  color: #aaa;
  font-size: 14px;
  margin-bottom: 1rem;
}

code {
  background-color: #2d2d2d;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  color: #000;
}

/* Container and layout */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--background-color);
  color: var(--text-color);
}

/* Main Content */
.mobile-main-content {
  margin-top: 20px;
  padding: 20px;
}

.web-main-content {
  display: flex;
  flex-direction: column;
  /* padding: 2rem 22rem; */
  align-items: center;
  justify-content: center;
}

.title-container {
  margin-top: 5rem;
  position: relative;
  margin-bottom: 1rem;
}

.banner {
  width: 100%;
  height: 92vh;
  object-fit: cover;
}

.small-banner {
  width: 100%;
  height: 10vh;
  object-fit: contain;
}

.flightportal {
  width: 100%;
  height: 90vh;
  object-fit: contain;
  border-radius: 12px;
  margin-bottom: 5rem;
}

.divider {
  border-bottom: 1px solid #2d2d2d;
}

.main-title {
  font-size: 3rem;
  color: #000;
}

.main-subheading {
  /* margin-top: 1rem; */
  font-size: 1rem;
  /* font-weight: 100; */
  margin: 0 2rem;
  color: #464646;
  text-align: center;
  margin-bottom: 3rem;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.overlay-container {
  position: absolute;
  top: 20%;
  left: 10%;
  transform: translateY(0);
  border-radius: 12px;
  width: 80%;
  padding: 2rem;
  text-align: left;
  color: white;
}

.overlay-title {
  font-size: 5rem;
  margin-bottom: 0.5rem;
}

.overlay-subheading {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #f2f5f9;
}

.overlay-time-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.overlay-time {
  font-size: 1rem;
  color: #e6e4e4;
}

.overlay-description-container {
  margin-top: 1rem;
  width: 40%;
  line-height: 1.4rem;
}

.overlay-description {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: "white";
}

.overlay-input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
  margin-bottom: 1rem;
}

.overlay-button {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 50px;
  border: 1px solid #e5e7eb !important;
  border-radius: 25px;
  cursor: pointer;
  padding: 10px;
  /* font-size: 0.8rem; */
  outline: none;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.overlay-button:hover {
  background-color: #e6e4e4; /* Darker shade on hover */
}

.overlay {
  position: absolute; /* Position the text absolutely */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for the text size */
  color: white; /* Change text color for visibility */
  text-align: center;
  font-size: 5rem;
  /* background-color: #1565c0; */
  width: 60%;
}

.how-it-works-container {
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Space between items */
  margin: 0 5rem;
  margin-bottom: 10rem;
  flex-wrap: wrap;
}

.vision-section {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align items horizontally */
  text-align: center; /* Center align text */
  margin: 10rem 0rem;
}

.vision-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  gap: 1rem;
}

.vision-card {
  /* background: #f8f7f7; */
  border-radius: 8px;
  width: 350px;
  align-items: left;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* margin: 0 1rem; */
}

.vision-card h2 {
  font-size: 2rem;
  color: #222;
  text-align: left;
  margin: 0.5rem 0;
}

.vision-card h3 {
  font-size: 1.5rem;
  color: #222;
  text-align: left;
  margin: 0.5rem 0;
  /* font-weight: 500; */
}

.vision-card p {
  font-size: 1rem;
  color: #7e7e7e;
  text-align: left;
  line-height: 1.3rem;
}

.vision-card span {
  color: #000;
}

.vision-card span:hover {
  text-decoration: underline;
}

.learn-more {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  display: flex;
  cursor: pointer;
}

.card-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 8px; /* Rounded top corners */
}

.card-image1 {
  width: 100%;
  height: 380px;
  object-fit: contain;
  border-radius: 8px; /* Rounded top corners */
}

.step-description,
.step-title {
  color: #000;
}

.chip {
  display: inline-block;
  padding: 5px 10px;
  font-size: 0.8rem;
  color: white;
  background-color: #2d2d2d;
  border-radius: 12px;
  margin-top: 5px;
  margin-right: 5px;
}

.date {
  font-size: 1rem;
  color: gray;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.step-container {
  margin: 0 10rem;
  flex: 1;
  display: flex;
}

.step-title {
  font-size: 2rem;
  color: #000;
  margin-bottom: 1rem;
}

.sub-step-title {
  font-size: 1.5rem;
  color: #000;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.step-description {
  font-size: 1rem;
  line-height: 1.6;
  color: #000;
}

.benefits-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  margin-bottom: 10rem;
}

.benefit-cards {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.benefit-card {
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  width: 15%;
  height: 200px;
  /* background-color: #f2f5f9; */
}

.benefit-card h3 {
  font-size: 1.5rem;
  color: #222;
  text-align: center;
  margin-bottom: 0.5rem;
}

.benefit-card p {
  font-size: 0.9rem;
  color: #7e7e7e;
  text-align: center;
}

.benefit-icon {
  margin-top: 1rem;
  font-size: 2rem; /* Adjust size as needed */
}

.sustainable-goals-section {
  text-align: center; /* Center align the content */
  margin-bottom: 10rem;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 40px; /* Space between grid items */
  padding: 3rem 5rem;
  margin-bottom: 10rem;
  width: 100%;
}

.grid-item {
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Prevent overflow */
  cursor: pointer;
}

.grid-item:hover {
}

.grid-item.disabled {
}

.flight-content {
  display: flex; /* Use flexbox for layout */
  max-height: 250px;
  flex-direction: row;
}

.image-container {
  position: relative; /* Position relative for absolute positioning of the chip */
}

.flight-image {
  width: 300px;
  height: 250px;
  border-radius: 8px;
  object-fit: cover;
}

.chip {
  position: absolute; /* Position the chip absolutely */
  top: 10px; /* Distance from the top */
  right: 10px; /* Distance from the right */
  background-color: #fff; /* Background color of the chip */
  color: #000; /* Text color */
  padding: 5px 10px; /* Padding for the chip */
  border-radius: 16px; /* Rounded corners for the chip */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: shadow for depth */
  font-size: 0.8rem; /* Font size */
  font-weight: 500; /* Bold text */
}

.flight-details {
  flex: 1;
  padding: 0 20px; /* Padding for text content */
  display: flex;
  max-width: 300px;
  justify-content: space-between;
  flex-direction: column; /* Stack text vertically */
}

.flight-details h4 {
  font-size: 0.8rem; /* Increase font size for location */
  color: #666;
  margin-bottom: 5px;
}

.flight-details h2 {
  font-size: 1.5rem; /* Increase font size for title */
  color: #333;
}

.flight-details p {
  font-size: 0.8rem;
  color: #666;
  /* margin-bottom: 10px; */
}

.flight-header {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 1rem;
}

.flight-info {
  display: flex;
  flex: 2.5;
  flex-direction: column;
  justify-content: space-between;
}

.flight-footer {
  display: flex;
  flex: 0.5;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.flight-footer p {
  font-size: 1rem;
  color: #000 !important;
}

.flight-footer span {
  font-size: 0.8rem;
  color: #949494;
}

.price-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.line-divider {
  border: none;
  border-top: 1px solid #e6e4e4;
}

.line-divider-light {
  border: none;
  border-top: 0.1px solid #ccc;
}

.testimonial {
  font-style: italic;
  color: #a8a8a8;
  border-left: 4px solid #2d2d2d;
  padding-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-form div {
  margin-bottom: 1rem;
}

.contact-form label {
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #000;
}

.contact-form input,
.contact-form textarea {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.contact-form button {
  background-color: #1565c0;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem;
  cursor: pointer;
}
</style>
