<template>
  <div class="faq-section">
    <h1 class="main-heading">{{ mainHeading }}</h1>
    <div
      v-for="(category, index) in categories"
      :key="index"
      :class="faqCategoryClass"
    >
      <h2 class="category-title" @click="toggleCategory(index)">
        {{ category.title }}
        <span class="toggle-icon">
          <Vue-feather
            :type="isOpen[index] ? 'chevron-up' : 'chevron-down'"
            size="24"
          />
        </span>
      </h2>
      <div v-if="isOpen[index]" class="faq-answers">
        <div v-for="(item, idx) in category.items" :key="idx" class="faq-item">
          <h3 class="question">{{ item.question }}</h3>
          <p class="answer">{{ item.answer }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from "@/utils/helper";
import { computed } from "vue";

export default {
  name: "FAQSection",
  props: {
    mainHeading: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isOpen: this.categories.map(() => false), // Initialize all categories as closed
    };
  },
  setup() {
    const faqCategoryClass = computed(() => {
      return isMobile() ? "faq-category-mobile" : "faq-category-desktop";
    });

    return {
      faqCategoryClass,
    };
  },
  methods: {
    toggleCategory(index) {
      this.isOpen[index] = !this.isOpen[index]; // Directly toggle the value
    },
  },
};
</script>

<style scoped>
.faq-section {
  margin: 2rem 0;
  color: black;
}

.main-heading {
  font-size: 3rem;
  color: #000;
  margin-bottom: 5rem;
  text-align: center;
}

.faq-category-desktop {
  padding: 1rem;
  border-bottom: 1px solid #f2f5f9;
  min-width: 800px;
  max-width: 800px;
}

.faq-category-mobile {
  margin-bottom: 1rem;
  border-bottom: 1px solid #f2f5f9;
  width: 100%; /* Allow it to take full width of the parent */
  max-width: 800px; /* Set a maximum width */
  margin-left: auto; /* Center the element */
  margin-right: auto; /* Center the element */
}

.category-title {
  padding: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle-icon {
  margin-left: 1rem;
}

.faq-answers {
  padding: 1rem;
}

.faq-item {
  margin-bottom: 0.5rem;
}

.question {
  font-weight: bold;
  font-size: 1rem;
}

.answer {
  margin: 0.5rem 0 1rem 0;
}
</style>
