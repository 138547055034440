<template>
  <div class="vision-cards-grid">
    <div class="vision-card" v-for="(item, index) in items" :key="index">
      <!-- <img :src="item.imgUrl" class="card-image" /> -->
      <vue-feather type="check-circle" size="18" style="color: green" />
      <h3>{{ item.heading }}</h3>
      <p>{{ item.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardGrid",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.vision-cards-grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(300px, 1fr)
  ); /* Responsive grid */
  gap: 1rem; /* Space between cards */
}

.vision-card {
  border-radius: 8px;
  margin: 1rem 0;
  text-align: left; /* Center align text */
  color: black;
}

.vision-card h3 {
  font-size: 1.5rem;
  color: #222;
  text-align: left;
  margin: 0.5rem 0;
  /* font-weight: 500; */
}

.vision-card p {
  font-size: 1rem;
  color: #7e7e7e;
  text-align: left;
  line-height: 1.3rem;
}

.card-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 8px; /* Rounded top corners */
}
</style>
