<template>
  <div class="how-it-works">
    <div class="line"></div>
    <div class="step" v-for="(step, index) in steps" :key="index">
      <div class="icon-container">
        <vue-feather
          :type="step.icon"
          size="24"
          class="step-icon"
        ></vue-feather>
      </div>
      <h3 class="step-title">{{ step.title }}</h3>
      <p class="step-description">{{ step.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepperComponent",
  props: {
    steps: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.how-it-works {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 5rem 0;
  position: relative;
}

.line {
  position: absolute;
  top: 25%; /* Center the line vertically */
  left: 0;
  right: 0;
  height: 2px; /* Line thickness */
  background-color: #f2f5f9; /* Line color */
  z-index: -1; /* Ensure the line is behind the icons */
}

.main-title {
  font-size: 3rem; /* Adjusted font size */
  color: #000;
}

.main-subheading {
  font-size: 1rem;
  margin: 1rem 0rem;
  color: #464646;
}

.step {
  text-align: center;
  flex: 1; /* Equal width for each step */
  padding: 1rem;
  max-height: max-content;
  max-width: 370px;
}

.icon-container {
  background-color: #f2f5f9; /* Light yellow background */
  border-radius: 50%;
  padding: 1rem;
  display: inline-block;
}

.step-icon {
  color: #333; /* Icon color */
}

.step-title {
  font-weight: bold;
  margin: 1rem 0 0.5rem;
  color: #333; /* Title color */
}

.step-description {
  color: #777; /* Description color */
}
</style>
