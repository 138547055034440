<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="app-container">
    <Header />

    <PhotoBanner
      :imageSrc="require('@/assets/lifestyle.webp')"
      title="Fly, Stream, and Earn"
      subheading="Join Our Virtual Travel Partner Program"
      iconType="send"
    />

    <!-- <div style="align-items: center; text-align: center">
      <div class="title-container">
        <h1 class="main-title">It’s easy to list your flights on Skyhost</h1>
      </div>
      <p class="main-subheading">
        Start sharing your skies in minutes and start earning.
      </p>
      <br />
      <br />
      <img src="@/assets/listing.png" alt="Skyhost Logo" class="flightportal" />
    </div> -->

    <!-- Blog Content -->
    <div :class="mainContentClass">
      <div class="title-container">
        <h1 class="main-title">Partner Benefits</h1>
        <!-- <h3 class="overlay-subheading" style="color: black">
          Partner Benefits
        </h3> -->
      </div>
      <p class="main-subheading">
        <!-- Join a global platform that turns your drone skills into earning
        potential. -->
      </p>
      <br />

      <div class="vision-cards">
        <div class="vision-card">
          <!-- <img src="@/assets/drones-can.jpg" class="card-image" /> -->
          <vue-feather
            type="dollar-sign"
            size="18"
            style="color: black"
          ></vue-feather>
          <h3>Earn Revenue</h3>
          <p>
            Monetize your drone footage and tours by reaching global audiences
          </p>
        </div>

        <div class="vision-card">
          <!-- <img src="@/assets/group-bond.jpg" class="card-image" /> -->
          <vue-feather
            type="globe"
            size="18"
            style="color: black"
          ></vue-feather>
          <h3>Expand Your Reach</h3>
          <p>
            Showcase your unique content through Skyhost's platform and connect
            with schools, explorers, and organizations worldwide.
          </p>
        </div>

        <div class="vision-card">
          <!-- <img src="@/assets/unsustainable.png" class="card-image" /> -->
          <vue-feather
            type="calendar"
            size="18"
            style="color: black"
          ></vue-feather>
          <h3>Flexible Opportunities</h3>
          <p>
            Work on your terms—offer live tours, create recorded experiences, or
            host unique educational sessions.
          </p>
        </div>
      </div>
      <br />
      <br />
      <!-- <video
        src="@/assets/campsite.mp4"
        autoplay
        muted
        loop
        class="banner-small"
      ></video>
      <br /> -->
      <br />
      <section class="vision-section">
        <div class="title-container">
          <h1 class="main-title">Customer Experience</h1>
        </div>
        <p class="main-subheading">
          Think of it as buying a ticket to live aerial exploration
        </p>
        <VerticalStepperComponent :steps="stepsData" />
        <img src="@/assets/howitworks.png" class="flightportal" />
      </section>

      <br />
      <br />
      <br />
      <br />

      <div class="title-container">
        <h1 class="main-title">Partner Requirements</h1>
      </div>
      <p class="main-subheading">
        To qualify as a partner, please review the following eligibility
        criteria.
      </p>
      <br />
      <br />
      <br />

      <!-- <ReviewsSection :reviews="reviewsData" /> -->

      <div class="how-it-works-container">
        <CardGrid :items="requirements" />
      </div>

      <FAQSection
        mainHeading="Your questions, answered"
        :categories="faqCategories"
      />

      <HireForm
        id="contact-form"
        :formData="formData"
        :submitForm="submitForm"
      />
    </div>
    <Footer />
  </div>
</template>

<script>
import { auth } from "@/plugins/firebase";
import { signOut } from "firebase/auth";
import { useSessionStore } from "../stores/session.js";
import { isMobile } from "@/utils/helper";
import { computed } from "vue";
import { amplitude } from "../plugins/amplitude.js";
import Header from "@/components/organisms/Header.vue";
import Footer from "@/components/Footer.vue";
import { addDoc, collection, getFirestore } from "firebase/firestore";
// import ReviewsSection from "@/components/organisms/Reviews.vue";
import HireForm from "@/components/organisms/HireForm.vue";
import VerticalStepperComponent from "@/components/organisms/StepperV.vue";
import CardGrid from "@/components/organisms/CardGrid.vue";
import FAQSection from "@/components/organisms/Faq.vue";
import PhotoBanner from "@/components/organisms/PhotoBanner.vue";

export default {
  components: {
    Header,
    Footer,
    // ReviewsSection,
    VerticalStepperComponent,
    HireForm,
    CardGrid,
    FAQSection,
    PhotoBanner,
  },
  data() {
    return {
      faqCategories: [
        {
          title: "Top questions",
          items: [
            {
              question: "What kind of drone do I need to host on Skyhost?",
              answer:
                "You’ll need a DJI drone capable of professional-quality live streaming. See our page for more details. https://skyhost.ai/how-it-works",
            },
            {
              question: "Are there any regulations I need to follow?",
              answer:
                "Drone operation laws vary by region. Be sure to comply with local aviation laws and regulations.",
            },
            {
              question: "How do I ensure smooth streaming?",
              answer:
                "A stable high-speed internet connection is essential. Make sure your streaming location has reliable connectivity, either via a mobile data connection or preferably Starlink.",
            },
            {
              question: "What if I encounter technical issues during a stream?",
              answer:
                "Skyhost provides a support team and troubleshooting guides to help you resolve any issues quickly.",
            },
          ],
        },
        {
          title: "Getting started",
          items: [
            {
              question: "How do I sign up as a pilot?",
              answer:
                "Reach out to us via the contact form on the bottom of the page or email us at hello@skyhost.ai",
            },
            {
              question: "Are the flights recurring?",
              answer:
                "Yes, you may be assigned recurring events with multiple time slots per day or week for convenient booking.",
            },
          ],
        },
        {
          title: "Earnings & fees",
          items: [
            {
              question: "How much can I earn on Skyhost?",
              answer:
                "Your earnings depend on your location and the number of flights.",
            },
            {
              question: "When will I receive my payments?",
              answer:
                "Payments are processed securely and typically once per month.",
            },
          ],
        },
        {
          title: "Hosting logistics",
          items: [
            {
              question: "How do I manage bookings?",
              answer:
                "Skyhost provides a booking dashboard where you can view, accepts, and manage reservations.",
            },
            {
              question: "What if bad weather disrupts my event?",
              answer:
                "You can reschedule or cancel events due to adverse weather conditions. Ensure to communicate with your clients in such cases.",
            },
            {
              question: "What safety measures should I follow?",
              answer:
                "Always operate your drone safely, avoid restricted zones, and inform clients of any potential risks in advance.",
            },
          ],
        },
        {
          title: "Policy & regulations",
          items: [
            {
              question: "Do I need a drone license to host on Skyhost?",
              answer:
                "Yes, you must hold a valid drone operator license to host on Skyhost, as per local regulations.",
            },
            {
              question: "What are the cancellation policies?",
              answer:
                "Skyhost offers flexible and strict cancellation policies. You can choose the one that best suits your hosting style.",
            },
            {
              question: "Are there any privacy rules I should know?",
              answer:
                "Yes, you must respect guest privacy and avoid live-streaming private or sensitive locations.",
            },
          ],
        },
        {
          title: "Support & resources",
          items: [
            {
              question: "How do I contact support?",
              answer:
                "You can reach Skyhost support via the Help Center or the in-app chat feature.",
            },
            {
              question: "Are there resources for improving my hosting skills?",
              answer:
                "Yes, Skyhost provides guides, tips, and community forums to help you enhance your hosting experience.",
            },
          ],
        },
      ],
      requirements: [
        {
          // imgUrl: require("@/assets/djidrone.jpg"),
          heading: "DJI Drone",
          description:
            "Professional-grade drones with high-quality cameras for visually stunning live streams.",
        },
        {
          // imgUrl: require("@/assets/internet.png"),
          heading: "Stable Internet Connection",
          description:
            "Reliable internet to avoid disruptions and ensure smooth streaming. Preferably Starlink.",
        },
        {
          // imgUrl: require("@/assets/license.png"),
          heading: "Valid Drone License",
          description:
            "Legally authorized to operate drones in the respective regions.",
        },
        {
          // imgUrl: require("@/assets/communication.png"),
          heading: "Good Communication Skills",
          description:
            "Ability to engage audiences during live streams and answer questions effectively.",
        },
        {
          // imgUrl: require("@/assets/remote-work.png"),
          heading: "Remote Work Flexibility",
          description:
            "Comfortable working in diverse remote and outdoor settings.",
        },
        {
          // imgUrl: require("@/assets/geography.png"),
          heading: "Knowledge of Local Geography",
          description:
            "Familiar with the region’s highlights to deliver insightful and engaging virtual tours.",
        },
      ],
      stepsData: [
        {
          icon: "search",
          title: "Browse and Book Your Tour",
          description:
            "Explore available drone tours, select your destination, and book your preferred time slot.",
        },
        {
          icon: "calendar",
          title: "Join on Your Scheduled Day",
          description:
            "Simply log in at the scheduled time and get ready for an unforgettable live experience.",
        },
        {
          icon: "mic",
          title: "Engage and Interact",
          description:
            "Ask questions, guide the drone, and connect with the pilot during the tour.",
        },
      ],
      reviewsData: [
        {
          name: "James H.",
          comment:
            "This was the easiest and most engaging field trip we've ever done. No travel, no stress—just pure excitement and immersive learning.",
          avatar: require("@/assets/mount-fuji.jpg"),
        },
        {
          name: "Sophia",
          comment:
            "Watching whales in their natural habitat was mesmerizing for the kids, and they learned so much about marine ecosystems directly from the guide.",
          avatar: require("@/assets/whale.jpg"),
        },
        {
          name: "Emily",
          comment:
            "Our 7th-graders were thrilled to interact with the drone pilot and ask questions in real time. It was an unforgettable way to bring our lesson to life!",
          avatar: require("@/assets/mt-fuji-hoei.jpg"),
        },
      ],
      formData: {
        name: "",
        email: "",
        school: "",
        message: "",
        type: "pilot",
        createdAt: new Date(),
      },
    };
  },
  methods: {
    scrollToFlyWithUs() {
      const contactFormSection = document.getElementById("contact-form");
      if (contactFormSection) {
        contactFormSection.scrollIntoView({ behavior: "smooth" });
      }
    },
    submitForm() {
      const db = getFirestore(); // Get Firestore instance
      const formCollection = collection(db, "inquiries"); // Replace with your collection name

      addDoc(formCollection, this.formData)
        .then(() => {
          console.log("Form data submitted successfully!");
          // Optionally, you can reset the form after submission
          this.formData = {
            name: "",
            email: "",
            school: "",
            message: "",
          };
        })
        .catch((error) => {
          console.error("Error submitting form data: ", error);
        });
    },
    login() {
      amplitude.track("Login from Blog", {
        type: "Blog to Login",
      });
      this.$router.push("/login");
    },
    logout() {
      signOut(auth)
        .then(() => {
          const sessionStore = useSessionStore();
          sessionStore.clearUser();
          this.$router.push("/");
        })
        .catch(() => {
          this.$router.push("/");
        });
    },
  },
  mounted() {
    const sessionStore = useSessionStore();
    auth.onAuthStateChanged((user) => {
      if (user) {
        sessionStore.userId = user.uid;
      } else {
        sessionStore.userId = null;
      }
    });
  },
  setup() {
    const mainContentClass = computed(() => {
      return isMobile() ? "mobile-main-content" : "web-main-content";
    });
    const headerClass = computed(() => {
      return isMobile() ? "mobile-header" : "web-header";
    });

    return {
      mainContentClass,
      headerClass,
    };
  },
};
</script>

<style scoped>
/* General Styles */
.step-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 1rem;
}

.step-list-item {
  margin-bottom: 1rem;
  color: #7e7e7e;
}

.step-list-item strong {
  color: #000;
  font-size: 1.5rem;
  font-weight: 400;
}

.nested-list {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-top: 0.5rem;
}

.step-item-label {
  font-weight: bold;
  color: #aaa;
  font-size: 14px;
  margin-bottom: 1rem;
}

code {
  background-color: #2d2d2d;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  color: #000;
}

/* Container and layout */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--background-color);
  color: var(--text-color);
}

/* Main Content */
.mobile-main-content {
  margin-top: 20px;
  padding: 20px;
}

.web-main-content {
  display: flex;
  flex-direction: column;
  /* padding: 2rem 22rem; */
  align-items: center;
  justify-content: center;
}

.title-container {
  margin-top: 5rem;
  position: relative;
  margin-bottom: 1rem;
}

.banner {
  width: 100%;
  height: 92vh;
  object-fit: cover;
}

.flightportal {
  width: 100%;
  /* height: 100vh; */
  object-fit: contain;
  border-radius: 12px;
}

.banner-small {
  width: 80%;
  height: 50vh;
  object-fit: cover;
  border-radius: 12px;
  margin-bottom: 5rem;
}

.divider {
  border-bottom: 1px solid #2d2d2d;
}

.main-title {
  font-size: 3rem;
  color: #000;
}

.main-subheading {
  /* margin-top: 1rem; */
  font-size: 1rem;
  /* font-weight: 100; */
  color: #464646;
  text-align: left;
  /* margin-bottom: 3rem; */
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.overlay-container {
  position: absolute;
  top: 20%;
  left: 10%;
  transform: translateY(0);
  border-radius: 12px;
  width: 80%;
  padding: 2rem;
  text-align: left;
  color: white;
}

.overlay-title {
  font-size: 5rem;
  margin-bottom: 0.5rem;
}

.overlay-subheading {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #f2f5f9;
}

.overlay-time-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.overlay-time {
  font-size: 1rem;
  color: #e6e4e4;
}

.overlay-description-container {
  margin-top: 1rem;
  width: 40%;
  line-height: 1.4rem;
}

.overlay-description {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: "white";
}

.overlay-input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
  margin-bottom: 1rem;
}

.overlay-button {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 50px;
  border: 1px solid #e5e7eb !important;
  border-radius: 25px;
  cursor: pointer;
  padding: 10px;
  /* font-size: 0.8rem; */
  outline: none;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.overlay-button:hover {
  background-color: #e6e4e4; /* Darker shade on hover */
}

.overlay {
  position: absolute; /* Position the text absolutely */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for the text size */
  color: white; /* Change text color for visibility */
  text-align: center;
  font-size: 5rem;
  /* background-color: #1565c0; */
  width: 60%;
}

.how-it-works-container {
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Space between items */
  margin-bottom: 8rem;
  flex-wrap: wrap;
}

.vision-section {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align items horizontally */
  text-align: left; /* Center align text */
}

.vision-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1rem;
}

.vision-card {
  /* background: #f8f7f7; */
  border-radius: 8px;
  width: 350px;
  align-items: left;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* margin: 0 1rem; */
}

.vision-card h2 {
  font-size: 2rem;
  color: #222;
  text-align: left;
  margin: 0.5rem 0;
}

.vision-card h3 {
  font-size: 1.5rem;
  color: #222;
  text-align: left;
  margin: 0.5rem 0;
  /* font-weight: 500; */
}

.vision-card p {
  font-size: 1rem;
  color: #7e7e7e;
  text-align: left;
  line-height: 1.3rem;
}

.vision-card span {
  color: #000;
}

.vision-card span:hover {
  text-decoration: underline;
}

.learn-more {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  display: flex;
  cursor: pointer;
}

.card-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 8px; /* Rounded top corners */
}

.card-image1 {
  width: 100%;
  height: 380px;
  object-fit: contain;
  border-radius: 8px; /* Rounded top corners */
}

.step-description,
.step-title {
  color: #000;
}

.chip {
  display: inline-block;
  padding: 5px 10px;
  font-size: 0.8rem;
  color: white;
  background-color: #2d2d2d;
  border-radius: 12px;
  margin-top: 5px;
  margin-right: 5px;
}

.date {
  font-size: 1rem;
  color: gray;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.step-container {
  margin: 0 10rem;
  flex: 1;
  display: flex;
}

.step-title {
  font-size: 2rem;
  color: #000;
  margin-bottom: 1rem;
}

.sub-step-title {
  font-size: 1.5rem;
  color: #000;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.step-description {
  font-size: 1rem;
  line-height: 1.6;
  color: #000;
}

.benefits-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  margin-bottom: 10rem;
}

.benefit-cards {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.benefit-card {
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  width: 15%;
  height: 200px;
  /* background-color: #f2f5f9; */
}

.benefit-card h3 {
  font-size: 1.5rem;
  color: #222;
  text-align: center;
  margin-bottom: 0.5rem;
}

.benefit-card p {
  font-size: 0.9rem;
  color: #7e7e7e;
  text-align: center;
}

.benefit-icon {
  margin-top: 1rem;
  font-size: 2rem; /* Adjust size as needed */
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 40px; /* Space between grid items */
  padding: 3rem 5rem;
  margin-bottom: 10rem;
  width: 100%;
}

.grid-item {
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Prevent overflow */
  cursor: pointer;
}

.grid-item:hover {
}

.grid-item.disabled {
  opacity: 0.7;
}

.flight-content {
  display: flex; /* Use flexbox for layout */
  max-height: 250px;
  flex-direction: row;
}

.image-container {
  position: relative; /* Position relative for absolute positioning of the chip */
}

.flight-image {
  width: 300px;
  height: 250px;
  border-radius: 8px;
  object-fit: cover;
}

.chip {
  position: absolute; /* Position the chip absolutely */
  top: 10px; /* Distance from the top */
  right: 10px; /* Distance from the right */
  background-color: #fff; /* Background color of the chip */
  color: #000; /* Text color */
  padding: 5px 10px; /* Padding for the chip */
  border-radius: 16px; /* Rounded corners for the chip */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: shadow for depth */
  font-size: 0.8rem; /* Font size */
  font-weight: 500; /* Bold text */
}

.flight-details {
  flex: 1;
  padding: 0 20px; /* Padding for text content */
  display: flex;
  max-width: 300px;
  justify-content: space-between;
  flex-direction: column; /* Stack text vertically */
}

.flight-details h4 {
  font-size: 0.8rem; /* Increase font size for location */
  color: #666;
  margin-bottom: 5px;
}

.flight-details h2 {
  font-size: 1.5rem; /* Increase font size for title */
  color: #333;
}

.flight-details p {
  font-size: 0.8rem;
  color: #666;
  /* margin-bottom: 10px; */
}

.flight-header {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 1rem;
}

.flight-info {
  display: flex;
  flex: 2.5;
  flex-direction: column;
  justify-content: space-between;
}

.flight-footer {
  display: flex;
  flex: 0.5;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.flight-footer p {
  font-size: 1rem;
  color: #000 !important;
}

.flight-footer span {
  font-size: 0.8rem;
  color: #949494;
}

.price-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.line-divider {
  border: none;
  border-top: 1px solid #e6e4e4;
}

.line-divider-light {
  border: none;
  border-top: 0.1px solid #ccc;
}

.testimonial {
  font-style: italic;
  color: #a8a8a8;
  border-left: 4px solid #2d2d2d;
  padding-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-form div {
  margin-bottom: 1rem;
}

.contact-form label {
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #000;
}

.contact-form input,
.contact-form textarea {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.contact-form button {
  background-color: #1565c0;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem;
  cursor: pointer;
}
</style>
