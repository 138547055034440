<!-- src/components/PhotoBanner.vue -->
<template>
  <div class="photo-banner">
    <img :src="imageSrc" class="photo-banner__image" />
    <div class="photo-banner__overlay">
      <Vue-feather
        :type="iconType"
        size="32"
        class="photo-banner__icon"
      ></Vue-feather>
      <h1 class="photo-banner__title">{{ title }}</h1>
      <h3 class="photo-banner__subheading">{{ subheading }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subheading: {
      type: String,
      required: true,
    },
    iconType: {
      type: String,
      required: true, // Make this prop required
    },
  },
  mounted() {
    console.log("Image source:", this.imageSrc);
  },
};
</script>

<style scoped>
.photo-banner {
  position: relative;
}

.photo-banner__image {
  width: 100%;
  height: 90vh;
  object-fit: cover;
  z-index: 0;
}

.photo-banner__overlay {
  position: absolute;
  top: 5%;
  left: 5%;
  transform: translateY(0);
  border-radius: 12px;
  width: 80%;
  padding: 2rem;
  text-align: left;
  color: white;
}

.photo-banner__title {
  font-size: 5rem;
  margin-bottom: 0.5rem;
}

.photo-banner__subheading {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #f2f5f9;
}

.photo-banner__icon {
  /* Additional styles for the icon can be added here */
}
</style>
